import { schema, isBoolean, isStringOrRegExpArray } from '@bugsnag/core-performance';
import { defaultNetworkRequestCallback, isNetworkRequestCallback } from '@bugsnag/request-tracker-performance';
import { isRoutingProvider } from './routing-provider.js';
import { defaultSendPageAttributes, isSendPageAttributes } from './send-page-attributes.js';

function createSchema(hostname, defaultRoutingProvider) {
    return Object.assign(Object.assign({}, schema), { releaseStage: Object.assign(Object.assign({}, schema.releaseStage), { defaultValue: hostname === 'localhost' ? 'development' : 'production' }), autoInstrumentFullPageLoads: {
            defaultValue: true,
            message: 'should be true|false',
            validate: isBoolean
        }, autoInstrumentNetworkRequests: {
            defaultValue: true,
            message: 'should be true|false',
            validate: isBoolean
        }, autoInstrumentRouteChanges: {
            defaultValue: true,
            message: 'should be true|false',
            validate: isBoolean
        }, generateAnonymousId: {
            defaultValue: true,
            message: 'should be true|false',
            validate: isBoolean
        }, routingProvider: {
            defaultValue: defaultRoutingProvider,
            message: 'should be a routing provider',
            validate: isRoutingProvider
        }, settleIgnoreUrls: {
            defaultValue: [],
            message: 'should be an array of string|RegExp',
            validate: isStringOrRegExpArray
        }, networkRequestCallback: {
            defaultValue: defaultNetworkRequestCallback,
            message: 'should be a function',
            validate: isNetworkRequestCallback
        }, sendPageAttributes: {
            defaultValue: defaultSendPageAttributes,
            message: 'should be an object',
            validate: isSendPageAttributes
        } });
}

export { createSchema };
