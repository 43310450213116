const createSpanAttributesSource = (document) => {
    const defaultAttributes = {
        url: {
            name: 'bugsnag.browser.page.url',
            getValue: () => document.location.href,
            permitted: false
        },
        title: {
            name: 'bugsnag.browser.page.title',
            getValue: () => document.title,
            permitted: false
        }
    };
    return {
        configure(configuration) {
            defaultAttributes.title.permitted = configuration.sendPageAttributes.title || false;
            defaultAttributes.url.permitted = configuration.sendPageAttributes.url || false;
        },
        requestAttributes(span) {
            for (const attribute of Object.values(defaultAttributes)) {
                if (attribute.permitted) {
                    span.setAttribute(attribute.name, attribute.getValue());
                }
            }
        }
    };
};

export { createSpanAttributesSource, createSpanAttributesSource as default };
