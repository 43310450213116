import { createClient, InMemoryQueue } from '@bugsnag/core-performance';
import createFetchDeliveryFactory from '@bugsnag/delivery-fetch-performance';
import { createFetchRequestTracker, createXmlHttpRequestTracker } from '@bugsnag/request-tracker-performance';
import { FullPageLoadPlugin } from './auto-instrumentation/full-page-load-plugin.js';
import { NetworkRequestPlugin } from './auto-instrumentation/network-request-plugin.js';
import { ResourceLoadPlugin } from './auto-instrumentation/resource-load-plugin.js';
import { RouteChangePlugin } from './auto-instrumentation/route-change-plugin.js';
import createBrowserBackgroundingListener from './backgrounding-listener.js';
import createClock from './clock.js';
import { createSchema } from './config.js';
import { createDefaultRoutingProvider } from './default-routing-provider.js';
import idGenerator from './id-generator.js';
import createOnSettle from './on-settle/index.js';
import makeBrowserPersistence from './persistence.js';
import createResourceAttributesSource from './resource-attributes-source.js';
import { createSpanAttributesSource } from './span-attributes-source.js';
import { WebVitals } from './web-vitals.js';

const backgroundingListener = createBrowserBackgroundingListener(document);
const spanAttributesSource = createSpanAttributesSource(document);
const clock = createClock(performance, backgroundingListener);
const persistence = makeBrowserPersistence(window);
const resourceAttributesSource = createResourceAttributesSource(navigator, persistence);
const fetchRequestTracker = createFetchRequestTracker(window, clock);
const xhrRequestTracker = createXmlHttpRequestTracker(XMLHttpRequest, clock, document);
const webVitals = new WebVitals(performance, clock, window.PerformanceObserver);
const onSettle = createOnSettle(clock, window, fetchRequestTracker, xhrRequestTracker, performance);
const DefaultRoutingProvider = createDefaultRoutingProvider(onSettle, window.location);
const BugsnagPerformance = createClient({
    backgroundingListener,
    clock,
    resourceAttributesSource,
    spanAttributesSource,
    deliveryFactory: createFetchDeliveryFactory(window.fetch, clock, backgroundingListener),
    idGenerator,
    schema: createSchema(window.location.hostname, new DefaultRoutingProvider()),
    plugins: (spanFactory, spanContextStorage) => [
        onSettle,
        new FullPageLoadPlugin(document, window.location, spanFactory, webVitals, onSettle, backgroundingListener, performance),
        // ResourceLoadPlugin should always come after FullPageLoad plugin, as it should use that
        // span context as the parent of it's spans
        new ResourceLoadPlugin(spanFactory, spanContextStorage, window.PerformanceObserver),
        new NetworkRequestPlugin(spanFactory, fetchRequestTracker, xhrRequestTracker),
        new RouteChangePlugin(spanFactory, window.location, document)
    ],
    persistence,
    retryQueueFactory: (delivery, retryQueueMaxSize) => new InMemoryQueue(delivery, retryQueueMaxSize)
});

export { DefaultRoutingProvider, BugsnagPerformance as default, onSettle };
