function createBrowserBackgroundingListener(document) {
    const callbacks = [];
    const backgroundingListener = {
        onStateChange(backgroundingListenerCallback) {
            callbacks.push(backgroundingListenerCallback);
            // trigger the callback immediately if the document is already 'hidden'
            if (document.visibilityState === 'hidden') {
                backgroundingListenerCallback('in-background');
            }
        }
    };
    document.addEventListener('visibilitychange', function () {
        const state = document.visibilityState === 'hidden'
            ? 'in-background'
            : 'in-foreground';
        for (const callback of callbacks) {
            callback(state);
        }
    });
    return backgroundingListener;
}

export { createBrowserBackgroundingListener as default };
