class Settler {
    constructor(clock) {
        this.settled = false;
        this.callbacks = new Set();
        this.clock = clock;
    }
    subscribe(callback) {
        this.callbacks.add(callback);
        // if we're already settled, call the callback immediately
        if (this.isSettled()) {
            callback(this.clock.now());
        }
    }
    unsubscribe(callback) {
        this.callbacks.delete(callback);
    }
    isSettled() {
        return this.settled;
    }
    settle(settledTime) {
        this.settled = true;
        for (const callback of this.callbacks) {
            callback(settledTime);
        }
    }
}

export { Settler };
