import { defaultNetworkRequestCallback } from '@bugsnag/request-tracker-performance';

const permittedPrefixes = ['http://', 'https://', '/', './', '../'];
class NetworkRequestPlugin {
    constructor(spanFactory, fetchTracker, xhrTracker) {
        this.spanFactory = spanFactory;
        this.fetchTracker = fetchTracker;
        this.xhrTracker = xhrTracker;
        this.configEndpoint = '';
        this.networkRequestCallback = defaultNetworkRequestCallback;
        this.logger = { debug: console.debug, warn: console.warn, info: console.info, error: console.error };
        this.trackRequest = (startContext) => {
            if (!this.shouldTrackRequest(startContext))
                return;
            const networkRequestInfo = this.networkRequestCallback({ url: startContext.url, type: startContext.type });
            if (!networkRequestInfo)
                return;
            if (typeof networkRequestInfo.url !== 'string') {
                this.logger.warn(`expected url to be a string following network request callback, got ${typeof networkRequestInfo.url}`);
                return;
            }
            const span = this.spanFactory.startSpan(`[HTTP]/${startContext.method.toUpperCase()}`, { startTime: startContext.startTime, makeCurrentContext: false });
            span.setAttribute('bugsnag.span.category', 'network');
            span.setAttribute('http.method', startContext.method);
            span.setAttribute('http.url', networkRequestInfo.url);
            return (endContext) => {
                if (endContext.state === 'success') {
                    span.setAttribute('http.status_code', endContext.status);
                    this.spanFactory.endSpan(span, endContext.endTime);
                }
            };
        };
    }
    configure(configuration) {
        this.logger = configuration.logger;
        if (configuration.autoInstrumentNetworkRequests) {
            this.configEndpoint = configuration.endpoint;
            this.xhrTracker.onStart(this.trackRequest);
            this.fetchTracker.onStart(this.trackRequest);
            this.networkRequestCallback = configuration.networkRequestCallback;
        }
    }
    shouldTrackRequest(startContext) {
        return startContext.url !== this.configEndpoint && permittedPrefixes.some((prefix) => startContext.url.startsWith(prefix));
    }
}

export { NetworkRequestPlugin };
