import { Settler } from './settler.js';

class RequestSettler extends Settler {
    constructor(clock, requestTracker) {
        super(clock);
        this.timeout = undefined;
        this.urlsToIgnore = [];
        this.outstandingRequests = 0;
        // unlike most other settlers we start settled as it's possible to not make
        // any requests at all
        // TODO: we actually should only be settled if there are no outstanding
        //       requests when constructed
        this.settled = true;
        requestTracker.onStart(this.onRequestStart.bind(this));
    }
    setUrlsToIgnore(urlsToIgnore) {
        this.urlsToIgnore = urlsToIgnore;
    }
    onRequestStart(startContext) {
        // if this is an excluded URL, ignore this request
        if (this.shouldIgnoreUrl(startContext.url))
            return;
        clearTimeout(this.timeout);
        this.settled = false;
        ++this.outstandingRequests;
        return (endContext) => {
            if (--this.outstandingRequests === 0) {
                // we wait 100ms to ensure that requests have actually stopped but don't
                // want the settled time to reflect that wait, so we record the time
                // here and use that when settling
                const settledTime = this.clock.now();
                this.timeout = setTimeout(() => { this.settle(settledTime); }, 100);
            }
        };
    }
    shouldIgnoreUrl(url) {
        return this.urlsToIgnore.some(regexp => regexp.test(url));
    }
}

export { RequestSettler as default };
