import { isObject } from '@bugsnag/core-performance';

const defaultSendPageAttributes = {
    referrer: true,
    title: true,
    url: true
};
function getPermittedAttributes(sendPageAttributes) {
    return Object.assign(Object.assign({}, defaultSendPageAttributes), sendPageAttributes);
}
function isSendPageAttributes(obj) {
    const allowedTypes = ['undefined', 'boolean'];
    const keys = Object.keys(defaultSendPageAttributes);
    return isObject(obj) && keys.every(key => allowedTypes.includes(typeof obj[key]));
}

export { defaultSendPageAttributes, getPermittedAttributes, isSendPageAttributes };
